import React from "react"

export const Announcement = () => {
    return (
        <a
            href="https://chromewebstore.google.com/detail/thatapp-tools-for-podio/phclnlgnabofihjdpocflflhkjddljff?hl=en-GB&utm_source=ext_sidebar"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => { }}
            style={{
                padding: "20px",
                background: "#000",
                color: "#fff",
                textAlign: "center",
                cursor: "pointer",
                display: "block",
                textDecoration: "none",
            }}
        >
            We now support backup of PWA workflows. Download our chrome Extension
        </a>
    )
}