import React from "react"
import ArrowRight from '../../../../assets/icons/webarrowright.svg'

export const ExtensionScreen = () => {
    return (
        <div
            style={{
                backgroundColor: "#071326",
                color: "white",
                padding: "50px",
                borderRadius: "16px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                width: "100%",
                // maxWidth: "80%",
                margin: "auto",
                gap: "15px",
            }}
        >
            <div style={{ maxWidth: "60%", width: "100%", minWidth: "300px", padding: "20px" }}>
                <h2 style={{ fontWeight: "bold", fontSize: "2rem" }}>
                    We’re Now Backing Up Podio Workflow Automation on ThatApp!
                </h2>
                <p style={{ fontSize: "16px", opacity: "0.8", marginTop: "8px" }}>
                    Great news! You can now backup your PWA workflows seamlessly within our
                    platform. Ensure your processes are safe, recoverable, and always
                    accessible.
                </p>
            </div>

            <button
                onClick={() => window.open("https://chromewebstore.google.com/detail/thatapp-tools-for-podio/phclnlgnabofihjdpocflflhkjddljff?hl=en-GB&utm_source=ext_sidebar", "_blank", "noopener,noreferrer")}
                style={{
                    backgroundColor: "#F0B429",
                    color: "black",
                    fontWeight: "600",
                    borderRadius: "999px",
                    padding: "10px 20px",
                    marginTop: "16px",
                    border: "none",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: 'center',
                    justifyContent: "space-between",
                    textWrap: "nowrap",
                    gap: "4px"
                }}
            >
                Get the extension

                <img src={ArrowRight} alt="arrow right icon" />
            </button>

            <div style={{ maxWidth: "60%", width: "100%", minWidth: "300px", padding: "20px" }}>
                <p style={{ fontSize: "12px", opacity: "0.6", marginTop: "8px" }}>
                    Download the browser extension to enable PWA workflow backups effortlessly.
                </p>
            </div>
        </div>
    );
};

