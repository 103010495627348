import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import { withRouter } from "react-router";
import refreshIcon from "../../assets/icons/refreshIcon.svg";
import { connect } from "react-redux";
import Config from "../../utils/Config";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  addMember,
  downloadEmailToApps,
  getAuditMembers,
  getEmailToApps,
  getHooksLog,
  getSyncedOrganizations,
} from "../../services/sync";
import { setAuditOrg } from "../../redux/actions/orgs";
import MemberGrid from "./AuditGrids/MemberGrid";
import Spaces from "./Resource/Spaces";
import BackedupWorkflow from "./Resource/BackedupWorkflow";
import { toast } from "helpers/apiRequests";
import LatestGridHooksClass from "../Hooks/LatestHooksGridClass";
import EmptyState from "components/EmptyState";
import auditApps from "../../assets/icons/auditApps.svg";
import auditUsers from "../../assets/icons/auditUsers.svg";
import auditItems from "../../assets/icons/auditItems.svg";
import auditSpaces from "../../assets/icons/auditSpaces.svg";
import { Typography } from "@material-ui/core";
import cancel from "../../assets/icons/cancel.svg";
import { useLocation } from "react-router-dom";
import EmailToApp from "./Resource/emailtoapp";

// import dropDown from "../../assets/icons/dropDown.svg";

let data = [
  { id: "1", tabTitle: "SPACES", tabContent: "Tab Content" },
  { id: "2", tabTitle: "MEMBERS", tabContent: "Tab Content 2" },
  { id: "3", tabTitle: "LOGS", tabContent: "Tab Content 3" },
  { id: "4", tabTitle: "FLOWS", tabContent: "Tab Content 4" },
  { id: "5", tabTitle: "EMAIL TO APP", tabContent: "Tab Content 5" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  hide: {
    display: "none",
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "5%",
  },
  headerText: {
    color: "black",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    background: "white",
  },
}));

const AuditView = ({ orgs, match, setAuditOrg, user }) => {
  const location = useLocation();
  const { selectedOrgId } = location.state || {};
  const { openWorkflow } = location.state || {};

  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [openMobile, setOpenMobile] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [orgId, setOrgId] = React.useState(selectedOrgId || orgs[0]?.org_id);
  // const [loader, setLoader] = React.useState(false);
  const [refreshLoader, setRefreshLoader] = React.useState(false);
  const roleArray = [
    {
      key: "light",
      title: "Light",
    },
    {
      key: "regular",
      title: "Regular",
    },
    {
      key: "admin",
      title: "Admin",
    },
  ];

  const handleDrawerOpen = () => {
    setOpen(!open);
    setOpenMobile(!openMobile);
  };

  const handleClose = () => {
    setAddUser(false);
  };

  const membersOption = (mem) => {
    let options = [];
    let uniqueArray = [];

    mem.forEach((element) => {
      if (!options.includes(element.user_id)) {
        options.push(element.user_id);
        uniqueArray.push({ user_id: element.user_id, name: element.name });
      }
    });
    return uniqueArray;
  };

  const spacesOption = (sp) => {
    let options = [];
    let uniqueArray = [];

    sp.forEach((element) => {
      if (!options.includes(element.space_id)) {
        options.push(element.space_id);
        uniqueArray.push({ space_id: element.space_id, name: element.name });
      }
    });
    return uniqueArray;
  };

  const [dataTab, setDataTab] = useState(openWorkflow === true ? "4" : "1");
  const [addUser, setAddUser] = useState(false);
  const [dashboardCounts, setDasboardCounts] = useState({
    users: 0,
    spaces: 0,
    apps: 0,
    items: 0,
    flows: 0,
  });
  const [none, setNone] = useState(false);

  const [members, setMembers] = React.useState({
    columns: [],
    data: [],
  });

  const [mem, setMem] = React.useState([]);
  const [_sp, setSp] = React.useState([]);
  const [formRole, setFormRole] = React.useState(null);
  const [formMember, setFormMember] = React.useState(null);
  const [formSpace, setFormSpace] = React.useState(null);
  const [hooksLog, setHooksLog] = React.useState(null);
  const [emails, setEmails] = useState(null);
  const [emailIsDownloading, setEmailIsDownloading] = useState(false);

  const handleAddMember = () => {
    if (formRole && formSpace && formMember) {
      addMember(
        `?org_id=${orgId}&space_id=${formSpace}&users=${formMember}&role=${formRole}`
      )
        .then((r) => {
          if (r.data.message === "Already added") {
            toast.notify("Member is already part of this space.", {
              type: "info",
            });
          } else {
            toast.notify("Added successfully.");
          }

          setAddUser(!addUser);
        })
        .catch((err) => {
          setAddUser(!addUser);
        });
    } else {
      toast.notify("Ensure your form is filled.", { type: "info" });
    }
  };

  const getAudits = useCallback(async () => {
    const _members = await getAuditMembers(`?org_id=${orgId}`);
    //Update Dashboard

    setDasboardCounts((prevState) => ({
      ...prevState,
      users: _members.count.unique_members,
    }));

    setMem(_members.data);
    let sp = [];
    _members.data.forEach((Ddata) => {
      const s = { space_id: Ddata.space_id, name: Ddata.space_name };
      sp.push(s);
      setMembers((prevState) => {
        const data = [...prevState.data];
        data.push({
          space: Ddata.space_name,
          name: Ddata.name,
          role: Ddata.role,
          user_id: Ddata.user_id,
          fields: Ddata.fields,
          link: Ddata.link,
          org_id: orgId,
          space_id: Ddata.space_id,
        });
        return { ...prevState, data };
      });
    });
    setSp(sp);
  }, [orgId]);

  const getEmailList = useCallback(async () => {
    try {
      const emailList = await getEmailToApps(orgId);
      setEmails(emailList)
    } catch (error) {
      toast.notify("An Error has occured", { type: "error" })
    }
  }, [orgId]);

  // const DownloadEmailToApps = async () => {
  //   setEmailIsDownloading(true);
  //   try {
  //     const response = await downloadEmailToApps(orgId);

  //     if (!(response instanceof Blob)) {
  //       throw new Error("Response is not a valid Blob.");
  //     }

  //     const blob = response;
  //     const url = URL.createObjectURL(blob);
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.download = "email-summary.csv";
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //     URL.revokeObjectURL(url);
  //   } catch (error) {
  //     toast.notify("Failed to download file. Please try again.", { type: "error" });
  //   } finally {
  //     setEmailIsDownloading(false);
  //   }
  // };

  const DownloadEmailToApps = async () => {
    setEmailIsDownloading(true);
    try {
      const response = await downloadEmailToApps(orgId);
      console.log(response)

      //if the response has the data and not falsy
      if (response.data?.url) {
        const link = document.createElement("a");
        link.href = response.data.url;
        link.download = "email-summary.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        toast.notify(response.message || "Email summary has been sent to your email.", { type: "success" });
      }
    } catch (error) {
      toast.notify("Failed to download file. Please try again.", { type: "error" });
    } finally {
      setEmailIsDownloading(false);
    }
  };


  useEffect(() => {
    let found = false;
    if (!Config.isEmpty(orgs)) {
      const x = orgs.find((x) => x.org_id === parseInt(orgId));
      if (!Config.isEmpty(x)) {
        setDasboardCounts({
          users: 0,
          spaces: x?.details?.mongo_summary?.count?.spaces,
          apps: x?.details?.mongo_summary?.count?.apps,
          items: x?.details?.mongo_summary?.count?.items,
          flows: x?.details?.mongo_summary?.count?.total_flows,
        });
        found = true;
      }
      // console.log(x)
    } else {
      const newResponse = getSyncedOrganizations();
      setAuditOrg(newResponse.data);
      const x = newResponse?.data?.find((x) => x?.org_id === parseInt(orgId));
      // console.log(x)
      if (!Config.isEmpty(x)) {
        setDasboardCounts({
          users: 0,
          spaces: x.details.mongo_summary.spaces,
          apps: x.details.mongo_summary.apps,
          items: x.details.mongo_summary.items,
          flows: x.details.mongo_summary.total_flows,
        });
        found = true;
      }
    }

    if (!found) return setNone(true);
    //Get Spaces
    getAudits();
    getEmailList();
    getHooksLog().then((res) => {
      setHooksLog(res.data);
    });
  }, [orgId, orgs, setAuditOrg, getAudits, getEmailList]);

  if (none)
    return (
      <div className={classes.root}>
        <CssBaseline />
        <Header baropen={open} handleDrawerOpen={handleDrawerOpen} />
        {isMobile ? (
          <Sidebar open={openMobile} handleDrawerOpen={handleDrawerOpen} />
        ) : (
          <Sidebar open={open} handleDrawerOpen={handleDrawerOpen} />
        )}
        <EmptyState />
      </div>
    );

  // if (loader) {
  //   return (
  //     <div className="material_block">
  //       <svg
  //         className="spinner"
  //         width="65px"
  //         height="65px"
  //         viewBox="0 0 66 66"
  //         xmlns="http://www.w3.org/2000/svg"
  //       >
  //         <circle
  //           className="circle"
  //           fill="#fff"
  //           strokeWidth="6"
  //           strokeLinecap="round"
  //           cx="33"
  //           cy="33"
  //           r="30"
  //         ></circle>
  //       </svg>
  //     </div>
  //   );
  // }

  // const handleBackButtonClick = () => {
  //   window.history.back();
  // };

  function formatNumber(num) {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(2) + "M";
    } else {
      return num.toLocaleString();
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header baropen={open} handleDrawerOpen={handleDrawerOpen} />
      {isMobile ? (
        <Sidebar open={openMobile} handleDrawerOpen={handleDrawerOpen} />
      ) : (
        <Sidebar open={open} handleDrawerOpen={handleDrawerOpen} />
      )}

      <main className={classes.content}>
        <div className={classes.toolbar} />

        <div className="audit">
          <div className="row justify-content-between mb-3 mt-2 auditheaderCont">
            <div className="col-md-6 auditHead">
              <h4 className="auditHeader">Audit</h4>
              <div className="id">ORG ID: {orgId}</div>
            </div>

            <div className="col-md-3 selectDiv">
              <select
                className="dropdown"
                value={orgId}
                onChange={(e) => setOrgId(e.target.value)}
                style={{
                  border: "100%",
                  padding: "0 10px 0 0",
                  appearance: "auto",
                }}
              >
                <option disabled value="">
                  Select Organization
                </option>
                {orgs.map((org, i) => {
                  return (
                    <option key={i} value={org.org_id}>
                      {org.name}
                    </option>
                  );
                })}
              </select>
              {/* <img alt="drop" src={dropDown} /> */}
            </div>
          </div>

          <div className="audit-tabs">
            <div
              style={{ borderRadius: "12px 0 0 12px" }}
              className="item item-first"
            >
              <div className="image">
                <img src={auditUsers} alt="users" />
              </div>
              <div className="detailsCont">
                <div className="label">Total Users</div>
                <div className="value">
                  {dashboardCounts?.users?.toLocaleString()}
                </div>
              </div>
            </div>

            <div className="item item-middile">
              <div className="image">
                <img src={auditSpaces} alt="spaces" />
              </div>
              <div className="detailsCont">
                <div className="label">Total Spaces</div>
                <div className="value">
                  {dashboardCounts?.spaces?.toLocaleString()}
                </div>
              </div>
            </div>

            <div className="item item-middile">
              <div className="image">
                <img src={auditApps} alt="apps" />
              </div>
              <div className="detailsCont">
                <div className="label">Total Apps</div>
                <div className="value">
                  {dashboardCounts?.apps?.toLocaleString()}
                </div>
              </div>
            </div>

            <div className="item item-last">
              <div className="image">
                <img src={auditItems} alt="items" />
              </div>
              <div className="detailsCont">
                <div className="label">Total Items</div>
                <div className="value">
                  {dashboardCounts?.items
                    ? formatNumber(dashboardCounts.items)
                    : dashboardCounts?.items?.toLocaleString()}
                </div>
              </div>
            </div>

            <div
              style={{ borderRadius: "0 12px 12px 0" }}
              className="item item-last"
            >
              <div className="image">
                <img src={auditItems} alt="items" />
              </div>
              <div className="detailsCont">
                <div className="label">Total Flows</div>
                <div className="value">
                  {dashboardCounts?.flows?.toLocaleString()}
                </div>
              </div>
            </div>
          </div>

          <div className="table-ag-grid">
            {/* eslint-disable-next-line eqeqeq */}

            <div
              style={{
                display: "flex",
                justifyContent: dataTab === "2" ? "space-between" : "flex-end",
                alignItems: "center",
                marginTop: "1.5%",
                width: "100%",
              }}
              className="audit-tab-btn-mobile"
            >
              <button
                className={dataTab === "2" ? "add-user" : "disappear"}
                onClick={() => setAddUser(!addUser)}
                style={{
                  color: "white",
                  backgroundColor: "#F0B429",
                  padding: "10px 15px",
                  borderRadius: "6px",
                  marginRight: "5%",
                }}
              >
                Add user to space
              </button>

              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{ width: "20px" }}
                  alt="refresh"
                  src={refreshIcon}
                />
                <button
                  style={{ marginLeft: "10px" }}
                  id="refreshThisGrid"
                  disabled={refreshLoader}
                  onClick={() => {
                    setRefreshLoader(true);
                    getEmailList();
                    setMembers({
                      columns: [],
                      data: [],
                    });
                    getAudits(); //Refresh members
                    setTimeout(() => {
                      setRefreshLoader(false);
                    }, 2000);
                  }}
                >
                  Refresh Grid
                </button>
              </div>
            </div>

            <div className={addUser ? "add-user-container" : "disappear"}>
              <div
                className="background"
                onClick={() => setAddUser(!addUser)}
              ></div>
              <div className="inner">
                <Typography variant="h6">
                  <div className={classes.modalHeader}>
                    <h5 className={classes.headerText}>Add Member</h5>
                    <div>
                      <img onClick={handleClose} alt="cancel" src={cancel} />
                    </div>
                  </div>
                </Typography>
                {/* <div className="subtitle">Add member to space</div> */}

                <div className="form-group">
                  <p>
                    Member <span style={{ color: "red" }}>*</span>
                  </p>
                  <select
                    className="form-control"
                    onChange={(e) => setFormMember(e.target.value)}
                  >
                    <option value={formMember}>Select a member</option>
                    {membersOption(mem).map((m) => {
                      return (
                        <option key={m.user_id} value={m.user_id}>
                          {m.name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="form-group">
                  <p>
                    Space <span style={{ color: "red" }}>*</span>
                  </p>

                  <select
                    className="form-control"
                    onChange={(e) => setFormSpace(e.target.value)}
                  >
                    <option value={formSpace}>Select a space</option>
                    {spacesOption(_sp).map((m) => {
                      return (
                        <option key={m.space_id} value={m.space_id}>
                          {m.name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="form-group">
                  <p>
                    Role <span style={{ color: "red" }}>*</span>
                  </p>

                  <select
                    className="form-control"
                    onChange={(e) => setFormRole(e.target.value)}
                  >
                    <option value={formRole}>Choose role</option>
                    {roleArray.map((m) => {
                      return (
                        <option key={m.key} value={m.key}>
                          {m.title}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <span
                    style={{
                      color: "white",
                      backgroundColor: "#F0B429",
                      padding: "10px 15px",
                      borderRadius: "6px",
                    }}
                    onClick={() => handleAddMember()}
                  >
                    ADD USER
                  </span>
                </div>
              </div>
            </div>

            <div className="tabCont">
              <div className="navigation mb-3">
                {data.map((item) => {
                  return (
                    <span
                      key={item.id}
                      style={{ textWrap: "nowrap" }}
                      className={item.id === dataTab ? "active" : ""}
                      onClick={() => setDataTab(item.id)}
                    >
                      {item.tabTitle}
                    </span>
                  );
                })}
                <Link
                  className="text-dark text-uppercase"
                  to="/dashboard/hooks"
                >
                  {" "}
                  <span>Hooks </span>{" "}
                </Link>
                <Link
                  className="text-dark text-uppercase"
                  to="/dashboard/manage-hook"
                >
                  {" "}
                  <span className="hooks_manager">Hooks Manager</span>{" "}
                </Link>
                <Link
                  className="text-dark text-uppercase"
                  to="/dashboard/raw-data"
                >
                  {" "}
                  <span className="raw_data_name">Raw Data</span>{" "}
                </Link>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginTop: "1.5%",
                  width: "35%",
                }}
                className="audit-tab-btn"
              >
                <button
                  className={dataTab === "2" ? "add-user" : "disappear"}
                  onClick={() => setAddUser(!addUser)}
                  style={{
                    color: "white",
                    backgroundColor: "#F0B429",
                    padding: "10px 15px",
                    borderRadius: "6px",
                    marginRight: "5%",
                  }}
                >
                  Add user to space
                </button>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    style={{ width: "20px" }}
                    alt="refresh"
                    src={refreshIcon}
                  />
                  <button
                    style={{ marginLeft: "10px" }}
                    id="refreshThisGrid"
                    disabled={refreshLoader}
                    onClick={() => {
                      setRefreshLoader(true);
                      setMembers({
                        columns: [],
                        data: [],
                      });
                      getAudits(); //Refresh members
                      getEmailList();
                      setTimeout(() => {
                        setRefreshLoader(false);
                      }, 2000);
                    }}
                  >
                    Refresh Grid
                  </button>
                </div>

                {
                  dataTab === "5" ?
                    <button
                      className=""
                      onClick={() => DownloadEmailToApps()}
                      style={{
                        color: "white",
                        backgroundColor: "#F0B429",
                        padding: "10px 15px",
                        borderRadius: "6px",
                        marginLeft: "5%",
                        marginRight: "5%",
                      }}
                    >
                      {emailIsDownloading ? "Downloading..." : "Download"}
                    </button>
                    :
                    null
                }
              </div>
            </div>

            <div className="content ">
              {refreshLoader && (
                <div className="py-5 mx-auto text-center">
                  <svg
                    className="spinner"
                    width="65px"
                    height="65px"
                    viewBox="0 0 66 66"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle"
                      fill="#fff"
                      strokeWidth="6"
                      strokeLinecap="round"
                      cx="33"
                      cy="33"
                      r="30"
                    ></circle>
                  </svg>
                </div>
              )}
              {dataTab === "1" && !refreshLoader && (
                <Spaces org_id={orgId} user={user} />
              )}
              {dataTab === "2" && !refreshLoader && (
                <MemberGrid audit={members} user={user} />
              )}
              {dataTab === "3" && !refreshLoader && hooksLog && (
                <LatestGridHooksClass hooksLog={hooksLog} user={user} />
              )}
              {dataTab === "4" && !refreshLoader && (
                <BackedupWorkflow org_id={orgId} user={user} />
              )}
              {dataTab === "5" && !refreshLoader && (
                <EmailToApp emails={emails} org_id={orgId} user={user} />
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

AuditView.Wrapper = styled.div`
  button {
    margin-left: 10px !important;
    font-size: 2px !important;
  }
`;

const mapStateToProps = (state) => ({
  user: state.user.authUser,
  orgs: state.sync.orgs,
});

const mapDispatchToProps = (dispatch) => ({
  setAuditOrg: (data) => dispatch(setAuditOrg(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AuditView));
